// eslint-disable-next-line import/no-cycle
import { trackEvent } from './mixpanel';
import {
  paramOrNul, toLowerCaseSpaced, paymentKindToAnalyticsName, getPurchaseScreenForPaymentSourcesEvent, getClientPlatform, matchPaths
} from '../../common/config/utils';
import env from '../config/variables';
import { getAdditionalPurchaseInfoFromURL } from '../config/api';
import { log } from '../config/app_logger';
import { getClickSourceForEvent, isPrerenderMode, getPayAsYouGoUsedProperty } from '../config/util';
import {
  trackEventAddToCardFB,
  trackEventSpentCreditFB,
  trackPurchaseEventFB,
  trackEventRegisterFB,
  trackEventAddToCardActionFB,
  trackFirstPurchaseEventFB,
  trackEventViewewContentFB,
  trackPurchaseStartedEventFB,
  trackEventProfileButtonFB
} from './facebook_analytics';
import { trackFirstPurchaseEventGtm, trackGTMEvent } from './google_tag_mahager_events';
import Const, { purchaseFlowTypes } from '../config/const';
import { appRoutes } from '../config/app_routes';

// Chat Related
export const StatusSuccess = 'success';
export const StatusBusy = 'busy';
export const StatusHangUp = 'hangup';
export const StatusNoAnswer = 'no answer';

export const getFlow = ({ additional, payAsYouGo }) => {
  if (additional) return purchaseFlowTypes.balance;
  if (payAsYouGo) return purchaseFlowTypes.payAsYouGo;
  return purchaseFlowTypes.minutes;
};

// Advisor profile View
const { ADVISOR_PROFILE_VIEW_MP_EVENT } = env;

const getContinueSessionValue = (getState) => {
  const { chat:{ continuedSession }, voipChat } = getState();
  return { 'continued session': continuedSession || voipChat?.continuedSession || 0 };
};

export const clearProps = (props) => {
  const clearedProps = {};
  Object.keys(props).forEach((k) => {
    clearedProps[k] = undefined;
  });
  window.dataLayer.push({
    ...clearedProps
  });
};

export const trackFriendsInviteEvent = (inviteVia, clickSource) => dispatch => {
  const eventName = 'friends invite';
  const properties = {
    'invite via': inviteVia,
    'click source': clickSource.toLowerCase()
  };
  dispatch(trackEvent(eventName, properties));
};

export const aditionalPurchaseProps = () => {
  const { purchaseDetails: { isPayg, flow } } = window.store.getState();
  let purchaseFlow = flow;
  const matchPaymentSources  = matchPaths({ pathname: appRoutes.paymentSources, path: window.location.pathname });
  if (!purchaseFlow && matchPaymentSources) {
    purchaseFlow = purchaseFlowTypes.paymentMethods;
  }
  const props = {
    ...getPayAsYouGoUsedProperty({ flow: purchaseFlow, isPayg }),
    flow: purchaseFlow
  };
  return props;
};

export const trackPurchaseStartedEvent = (purchaseData, conversionEventId) => (dispatch, getState) => {
  const eventName = 'purchase started';
  const properties = {
    ...purchaseData,
    ...getContinueSessionValue(getState),
    ...aditionalPurchaseProps()
  };

  dispatch(trackEvent(eventName, properties));
  trackPurchaseStartedEventFB(conversionEventId);
};

const purchaseEventAdditionalParams = (purchaseRes) => {
  const {
    paypalPayerId, paypalTransactionId, paypalBillingAgreementId, paypalInvoiceId,
    spendingLimits
  } = purchaseRes;

  const destination = !!spendingLimits && !!spendingLimits.length ? { destination: 'jumio' } : null;
  return ({
    ...paramOrNul('paypal payer id', paypalPayerId),
    ...paramOrNul('paypal transaction id', paypalTransactionId),
    ...paramOrNul('paypal billing agreement id', paypalBillingAgreementId),
    ...paramOrNul('paypal invoice id', paypalInvoiceId),
    ...paramOrNul('spending limits exceeded', spendingLimits),
    ...destination
  });
};

const addAdditonalPurchasePropsAndTrackEvent = (eventName, params, purchaseURL, getUser) => (dispatch) => {
  const trackPurchase = (extraParams) => {
    dispatch(trackEvent(eventName, { ...params, ...extraParams }));
    if (getUser) getUser();
  };
  if (!purchaseURL) {
    log('Mixpanel', 'has no URL, tracking default params');
    trackPurchase();
    return;
  }
  getAdditionalPurchaseInfoFromURL(purchaseURL)
    .then((res) => {
      log('Mixpanel', 'got extra params, tracking with params');
      trackPurchase(purchaseEventAdditionalParams(res));
    }).catch(() => {
      log('Mixpanel', 'got extra params, tracking default params');
      trackPurchase();
      dispatch(trackEvent(eventName, params));
    });
};

export const trackPurchaseEvent = (purchaseData, purchaseURL, getUser, conversionEventId) => (dispatch, getState) => {
  const { user: { user: { purchasesCount, email } } } = getState();
  const price = purchaseData['base price'];
  const currency = purchaseData['local currency'];
  const eventName = 'Purchase';
  const properties = {
    ...purchaseData,
    ...getContinueSessionValue(getState),
    'transaction total': purchaseData.credit,
    ...aditionalPurchaseProps()
  };
  const propertiesGTM = {
    price,
    currency,
    'email address': email
  };
  trackPurchaseEventFB(price, currency, conversionEventId);
  if (purchasesCount === 0) {
    trackFirstPurchaseEventFB(price, currency, conversionEventId);
    trackFirstPurchaseEventGtm(propertiesGTM);
  }
  dispatch(addAdditonalPurchasePropsAndTrackEvent(eventName, properties, purchaseURL, getUser));
};

export const trackPurchaseFailedEvent = (purchaseData, purchaseURL) => (dispatch, getState) => {
  const { extraEventParams } = purchaseData;
  const eventName = 'purchase failed';
  const properties = {
    ...getContinueSessionValue(getState),
    ...purchaseData,
    ...aditionalPurchaseProps()
  };
  dispatch(addAdditonalPurchasePropsAndTrackEvent(eventName, properties, purchaseURL));
  if (extraEventParams && extraEventParams.length !== 0) clearProps({ ...toLowerCaseSpaced(extraEventParams) });
};

const mapLoginTypeToSignUpMethod = loginType => {
  const typesList = {
    google: 'Google Plus',
    email: 'Email',
    facebook: 'Facebook'
  };
  return typesList[loginType];
};

export const trackLoginEvent = (signupFlow) => (dispatch, getState) => {
  const { user: { user: { loginType } } } = getState();
  const eventName = 'sign in';
  const properties = {
    'signup flow': signupFlow,
    'signup method': mapLoginTypeToSignUpMethod(loginType)
  };
  dispatch(trackEvent(eventName, properties));
};

export const trackRegisterEvent = (signupFlow, conversionEventId) => (dispatch, getState) => {
  const eventName = 'signup';
  const { user: { user: { loginType, countryName } } } = getState();
  const properties = {
    'signup flow': signupFlow,
    'signup method': mapLoginTypeToSignUpMethod(loginType),
    'registration country name' : countryName
  };
  dispatch(trackEvent(eventName, properties));
  trackEventRegisterFB(loginType, conversionEventId);
};

export const trackLogoutEvent = () => (dispatch) => {
  const eventName = 'sign out';
  dispatch(trackEvent(eventName));
};

export const trackCategoryEvent = (categoryEventParams) => (dispatch) => {
  const eventName = 'category';
  const properties = {
    'category id': categoryEventParams.categoryId,
    'category name': categoryEventParams.categoryName,
    'click souce': categoryEventParams.clickSource,
    ...paramOrNul('advisor id', categoryEventParams.advisorId)
  };
  dispatch(trackEvent(eventName, properties));
};

export const getSessionType = (chatType) => {
  let sessionType = '';
  switch (chatType) {
    case Const.chatType.text:
      sessionType = 'chat';
      break;
    case Const.chatType.voice:
      sessionType = 'voice';
      break;
    case Const.chatType.video:
      sessionType = 'vc';
      break;
    default:
      break;
  }
  return sessionType;
};

const startChatProperties = (chat, sessionType) => {
  const {
    advisorId, orderId, analytics, name, continuedSession, startChatClickSource, homepageSection, isPayg, flow
  } = chat;
  return {
    ...toLowerCaseSpaced(analytics),
    ...paramOrNul('advisor id', advisorId),
    ...paramOrNul('advisor name', name),
    ...paramOrNul('session type', sessionType),
    ...paramOrNul('click source', startChatClickSource),
    ...paramOrNul('order id', orderId),
    'continued session': !continuedSession || continuedSession === undefined ? 0 : continuedSession,
    ...paramOrNul('homepage section', homepageSection),
    ...getPayAsYouGoUsedProperty({ flow, isPayg }),
    flow
  };
};

const trackPaygRelatedProps = (payAsYouGo, propValue) => (payAsYouGo ? undefined : propValue);

export const trackChatAttemptEvent = (chatType, chat, dispatch) => {
  const {
    initialDuration, initialCharged, analytics, initialVoipCharged, initialVoipDuration, isPayg
  } = chat;
  const eventNameOpt = 'live session attempt';
  const eventNameMP = `${ getSessionType(chatType) } attempt`;
  const chatProps = {
    ...startChatProperties(chat, getSessionType(chatType)),
    ...paramOrNul(`${ getSessionType(chatType) } initial minutes`, trackPaygRelatedProps(isPayg, (initialDuration || initialVoipDuration) / 60)),
    ...paramOrNul(`${ getSessionType(chatType) } initial credit`, trackPaygRelatedProps(isPayg, initialCharged || initialVoipCharged))
  };
  trackGTMEvent(eventNameOpt, { ...chatProps, platform: getClientPlatform() });
  dispatch(trackEvent(eventNameMP, { ...chatProps }));
  clearProps({ ...toLowerCaseSpaced(analytics) });
};

export const trackChatStartedEvent = (chatType, status, chatData, user, dispatch) => {
  const { numberOfChatOrders, numberOfVcOrders, numberOfVoiceCallOrders } = user;
  const {
    initialDuration, initialCharged, initialVoipCharged, initialVoipDuration, analytics, isPayg
  } = chatData;
  const chatProps = {
    ...startChatProperties(chatData, getSessionType(chatType)),
    ...paramOrNul('number of chat orders', numberOfChatOrders),
    ...paramOrNul('number of voice orders', numberOfVoiceCallOrders),
    ...paramOrNul('number of vc orders', numberOfVcOrders)
  };
  const  mpProps = {
    ...paramOrNul(`${ getSessionType(chatType) } initial minutes`, trackPaygRelatedProps(isPayg, (initialDuration || initialVoipDuration) / 60)),
    ...paramOrNul(`${ getSessionType(chatType) } initial credit`, trackPaygRelatedProps(isPayg, initialCharged || initialVoipCharged)),
    ...paramOrNul([`${ getSessionType(chatType) } status`], status)
  };
  const  optimoveProps = {
    ...paramOrNul('session initial minutes', trackPaygRelatedProps(isPayg, (initialDuration || initialVoipDuration) / 60)),
    ...paramOrNul('session initial credit', trackPaygRelatedProps(isPayg, initialCharged || initialVoipCharged)),
    ...paramOrNul('session status', status),
    platform: getClientPlatform()
  };
  trackGTMEvent('live session started', { ...chatProps, ...optimoveProps });
  dispatch(trackEvent(`${ getSessionType(chatType) } started`, { ...chatProps, ...mpProps }));
  clearProps({ ...toLowerCaseSpaced(analytics) });
};

export const trackDurationPickExitdMPEvent = (chatType, payload) => (dispatch) => {
  dispatch(trackEvent(`${ getSessionType(chatType) } duration pick exit`, payload));
};

export const trackDurationPickedMPEvent = (chatType, payload) => (dispatch) => {
  let eventName = '';
  switch (chatType) {
    case Const.chatType.text:
      eventName = 'chat duration picked';
      break;
    case Const.chatType.voice:
      eventName = 'voice duration picked';
      break;
    case Const.chatType.video:
      eventName = 'vc duration picked';
      break;
    default:
      break;
  }
  dispatch(trackEvent(eventName, payload));
};

export const trackBaseFavoriteEventMP = (isFavorite, advisorId, clickSource, dispatch, conversionEventId) => {
  const eventName = isFavorite ? 'favorite advisor' : 'unfavorite advisor';
  const properties = {
    'advisor id': advisorId,
    'click source': clickSource
  };
  if (isFavorite) {
    trackEventAddToCardFB(advisorId, conversionEventId);
  }
  dispatch(trackEvent(eventName, properties));
};

export const parseAndTrackAnalyticEvents = (analyticEvents, extraProps) => {
  if (!analyticEvents) return;
  const convertedAnalyticEvents = toLowerCaseSpaced(analyticEvents);
  Object.keys(convertedAnalyticEvents).forEach((eventName) => {
    window.store.dispatch(trackEvent(eventName, { ...convertedAnalyticEvents[eventName], ...extraProps }));
    clearProps({ ...convertedAnalyticEvents[eventName] });
  });
};

export const trackEventDeleteOrder = (order) => (dispatch) => {
  const {
    id, advisorId, orderType, state
  } = order;
  const properties = {
    'order id' : id,
    'advisor id': advisorId,
    'order type': orderType,
    'order state': state
  };
  dispatch(trackEvent('order deleted', properties));
};

export const trackLeaveFeedbackEvent = (feedbackData, dispatch) => {
  const eventName = 'leave feedback';
  dispatch(trackEvent(eventName, feedbackData));
};

export const trackRedeemPromoFailedEvent = (fullMessages, code, error, clickSource, dispatch) => {
  const eventName = 'redeem promo failed';
  const properties = {
    'promo status': fullMessages,
    promo: code,
    'promo error': `${ error.status }`,
    'click source': clickSource
  };
  dispatch(trackEvent(eventName, properties));
};

export const trackRedeemPromoEvent = (res, code, clickSource, dispatch) => {
  const eventName = 'redeem promo';
  const properties = {
    'promo status': res.creditText,
    'promo ': code,
    'credits ': res.creditAmount,
    'click source': clickSource,
    ...paramOrNul('referring user', res.referringUserId)
  };
  dispatch(trackEvent(eventName, properties));
};

export const trackAdvisorProfileViewEvent = (params) => (dispatch) => {
  const eventName = 'advisor profile view';
  const {
    advisorId, section, conversionEventId, location, nickname,
    serviceTitle, profilePictureUrl, rating, readingsCount, yearJoined, profileVideoUrl
  } = params;
  trackEventViewewContentFB(advisorId, conversionEventId);
  if (ADVISOR_PROFILE_VIEW_MP_EVENT) {
    const { clickSource } = getClickSourceForEvent({ location });
    const properties = {
      'advisor id': advisorId,
      'click source': clickSource.toLowerCase(),
      ...paramOrNul('user id', window.currentUserId),
      ...paramOrNul('section', section),
      'advisor name': nickname,
      'advisor subtitle': serviceTitle,
      'advisor image url': profilePictureUrl,
      'advisor rating': rating,
      'advisor readings since data': `${ readingsCount } readings since ${ yearJoined }`,
      'advisor video url': profileVideoUrl
    };
    dispatch(trackEvent(eventName, properties));
  }
};

export const trackProfileChatButton = (params) => (dispatch) => {
  const {
    ppm, advisorId, mode, clickSource, section
  } = params;
  const payload = {
    ppm,
    tryout: false,
    'advisor id': advisorId,
    'click source': clickSource,
    ...paramOrNul('section', section)
  };
  let eventName = '';
  switch (mode) {
    case 'voice':
      eventName = 'profile voice button';
      break;
    case 'video':
      eventName = 'profile vc button';
      break;
    default:
      eventName = 'profile chat button';
      break;
  }
  dispatch(trackEvent(eventName, payload));
};

export const trackPlayingAdvisorProfileVideoEvent = (params) => (dispatch) => {
  const eventName = 'Playing advisor profile video';
  const {
    id, clickSource, section, duration
  } = params;
  const properties = {
    'advisor id': id,
    'click source': clickSource.toLowerCase(),
    ...paramOrNul('section', section),
    'play time': duration
  };
  dispatch(trackEvent(eventName, properties));
};

export const trackEventSpentCredit = (price, currency, ordetType, userId, orderId) => {
  if (price > 0) trackEventSpentCreditFB(price, currency, ordetType, userId, orderId);
};

export const trackEventAddToCardAction = (advisorId) => () => {
  trackEventAddToCardActionFB(advisorId);
};

export const trackTranscriptEvent = (advisorId, duration) => (dispatch) => {
  const eventName = 'transcript view';
  if (!advisorId) return;
  const properties = {
    'advisor id': advisorId,
    'transcript view duration' : duration
  };
  dispatch(trackEvent(eventName, properties));
};

export const trackEventProfileButton = (conversionEventId) => () => {
  trackEventProfileButtonFB(conversionEventId);
};

export const trackPaymentSourcesEvent = (clickSource) => (dispatch, getState) => {
  const eventName = 'payment sources screen';
  const { paymentSources } = getState();
  if (!paymentSources) return;
  let paymentKind = 'none';
  if (paymentSources.current) {
    const { paymentSources: { current: { kind } } } = getState();
    paymentKind = kind;
  }
  const numberOfCC = (paymentSources.existing || []).filter(
    source => !source.errorText && source.kind === 'cc'
  ).length;
  const properties = {
    'purchase screen': getPurchaseScreenForPaymentSourcesEvent(clickSource),
    'default payment method': paymentKindToAnalyticsName(paymentKind),
    'number of credit cards': numberOfCC,
    ...aditionalPurchaseProps()
  };
  dispatch(trackEvent(eventName, properties));
};

export const trackSmartBannerEvent = (params) => (dispatch) => {
  const eventName = 'smart banner';
  const {
    sessionsCount, secondsFromSessionBegan
  } = params;
  const properties = {
    'sessions count': sessionsCount,
    'seconds from session began': secondsFromSessionBegan
  };
  dispatch(trackEvent(eventName, properties));
};

export const purchase3DSRequiredEvent = (params) => (dispatch) => {
  const eventName = 'purchase 3DS required';
  const properties = params;
  dispatch(trackEvent(eventName, properties));
};

export const purchase3DSFailedEvent = (params) => (dispatch) => {
  const eventName = 'purchase 3DS failed';
  const properties = params;
  dispatch(trackEvent(eventName, properties));
};

const checkOptimoveUserId = ({ eventName, params }) => {
  let optimoveSDKAPIGetUserId = window.optimoveSDK?.API.getUserId();
  if (!optimoveSDKAPIGetUserId) {
    const interval = setInterval(() => {
      optimoveSDKAPIGetUserId = window.optimoveSDK?.API.getUserId();
      if (optimoveSDKAPIGetUserId) {
        trackGTMEvent(eventName, params, true);
        clearInterval(interval);
      }
    }, 1000);
  }
};

export const  trackPageVisit = (pageTitle, withUserId = false) => {
  if (isPrerenderMode()) return;
  const eventName = 'page visit';
  const optimoveUserId = window.optimoveSDK?.API.getUserId();
  const params = { url: window.location.href, 'page title': pageTitle };
  log('Optimove', `track page visit: ${ pageTitle }`);
  if (withUserId && !optimoveUserId) {
    checkOptimoveUserId({ eventName, params });
    return;
  }
  trackGTMEvent(eventName, params, true);
};
